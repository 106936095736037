<template>
  <aside
    class="sticky top-0 hidden h-screen w-16 flex-none bg-slate-800 md:block"
    v-if="hasPermission('view-patient')"
  >
    <Sidenav />
  </aside>
</template>

<script lang="ts" setup>
import { hasPermission } from "@/libraries/utils/hasPermission";
import Sidenav from "./sidenav/Sidenav.vue";
</script>
