<template>
  <div class="flex flex-col space-y-1">
    <label
      class="text-gray-800/70"
      :class="textSize + ' ' + (forTextarea ? 'ml-[calc(0.75rem+1px)]' : '')"
      >{{ label }}<span class="text-red-600" v-if="required"> *</span>
      <InfoBox class="ml-1" v-if="infoMessage">
        {{ infoMessage }}
      </InfoBox>
      <slot name="infoMessage"></slot>
    </label>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import InfoBox from "@/components/ui/InfoBox.vue";
import { TextSizes } from "@/libraries/UI/text";

const props = withDefaults(
  defineProps<{
    label: string;
    infoMessage?: string;
    forTextarea?: boolean;
    required?: boolean;
    textSize?: TextSizes;
  }>(),
  {
    forTextarea: false,
    required: false,
    textSize: "text-sm",
  },
);
</script>
