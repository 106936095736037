<template>
  <flux-tooltip
    class="outline-none"
    :tabindex="-1"
    :content="$t(`appointment.status.${status}`)"
    placement="top"
  >
    <AppointmentStatusIcon :status="status" />
  </flux-tooltip>
</template>

<script lang="ts" setup>
import { AppointmentStatus } from "@/models/Appointment";
import AppointmentStatusIcon from "./AppointmentStatusIcon.vue";

defineProps<{
  status: AppointmentStatus;
}>();
</script>
