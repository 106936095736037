<template>
  <div>
    Question:
    <flux-input v-model:modelValue="item.text"></flux-input>
    <div>
      Options
      <div class="grid grid-cols-2 gap-2">
        <div
          class="contents"
          v-for="(option, index) in item.answerOption"
          :key="index"
        >
          <input
            class="animate block appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 text-sm outline-none duration-75 hover:border-gray-500 focus:border-blue-600 focus:ring"
            :value="option.valueCoding.code"
            @input="
              $event.target !== null && 'value' in $event.target
                ? (option.valueCoding.code = $event.target.value)
                : (option.valueCoding.code = null)
            "
            type="text"
            @keydown.enter="
              item.answerOption.push({ valueCoding: { code: '', display: '' } })
            "
          />
          <input
            class="animate block appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 text-sm outline-none duration-75 hover:border-gray-500 focus:border-blue-600 focus:ring"
            v-model="option.valueCoding.display"
            type="text"
            @keydown.enter="
              item.answerOption.push({ valueCoding: { code: '', display: '' } })
            "
          />
        </div>
      </div>
      <i
        class="far fa-plus text-gray-500 hover:text-gray-600"
        @click="
          item.answerOption.push({ valueCoding: { code: '', display: '' } })
        "
      ></i>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends Coding">
import { ChoiceItem, Coding } from "@/libraries/questionnaires/item";

defineProps<{ item: ChoiceItem<T> }>();
</script>
