<template>
  <label
    :class="[
      'inline-flex select-none items-center gap-2',
      disabled ? 'cursor-not-allowed text-gray-500' : 'cursor-pointer',
    ]"
    ><slot></slot
  ></label>
</template>
<script lang="ts" setup>
defineProps<{
  disabled?: boolean;
}>();
</script>
