<template>
  <div>
    Question:
    <flux-input v-model:modelValue="item.text"></flux-input>
    <div>
      Options
      <div class="grid grid-cols-3 gap-2">
        <input
          class="animate block appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 text-sm outline-none duration-75 hover:border-gray-500 focus:border-blue-600 focus:ring"
          v-for="(option, index) in item.answerOption"
          v-model="option.valueString"
          type="text"
          @keydown.enter="item.answerOption.push({ valueString: '' })"
          :key="index"
        />
      </div>
      <i
        class="far fa-plus text-gray-500 hover:text-gray-600"
        @click="item.answerOption.push({ valueString: '' })"
      ></i>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends Coding">
import { Coding, OpenChoiceItem } from "@/libraries/questionnaires/item";

defineProps<{
  item: OpenChoiceItem<T>;
}>();
</script>
