<template>
  <flux-alert v-if="errors" v-show="errors" type="error" :closable="false">
    <h3 class="m-0 text-sm font-semibold">{{ $t("validation.title") }}</h3>
    <ValidationErrorsText class="text-sm" :errors="errors" />
  </flux-alert>
</template>

<script lang="ts" setup>
import { MessageBag } from "@/libraries/utils/MessageBag";
import ValidationErrorsText from "./ValidationErrorsText.vue";

defineProps<{ errors: MessageBag }>();
</script>
