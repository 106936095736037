<template>
  <span>
    <img
      v-if="avatar && avatar.uses_photo"
      :src="avatar.photo_data_uri"
      style="
        border-radius: 50%;
        box-shadow: inset 0px 0px 1px black;
        display: block;
      "
      :width="big ? 40 : 20"
      :height="big ? 40 : 20"
    />
    <svg
      v-else-if="avatar"
      :style="{
        display: 'block',
        width: big ? '40px' : '20px',
        height: big ? '40px' : '20px',
      }"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50%" cy="50%" r="50%" :fill="'#' + avatar.color" />
      <!-- <rect width="100%" height="100%" :rx="big ? '8' : '4'" :ry="big ? '8' : '4'" :fill="'#' + avatar.color" /> -->
      <text
        x="50%"
        y="50%"
        dy=".35em"
        dx="0 1"
        text-anchor="middle"
        :style="{
          fill: 'white',
          'font-size': big ? '20px' : '11px',
        }"
      >
        {{ avatar.initials }}
      </text>
    </svg>
    <svg
      v-else-if="avatar"
      :style="{
        display: 'block',
        width: big ? '40px' : '20px',
        height: big ? '40px' : '20px',
      }"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50%" cy="50%" r="50%" :fill="'#ccc'" />
      <!-- <rect width="100%" height="100%" :rx="big ? '8' : '4'" :ry="big ? '8' : '4'" :fill="'#' + avatar.color" /> -->
      <text
        x="50%"
        y="50%"
        dy=".35em"
        dx="0 1"
        text-anchor="middle"
        :style="{
          fill: 'white',
          'font-size': big ? '20px' : '11px',
        }"
      >
        <i class="far fa-times" />
      </text>
    </svg>
  </span>
</template>

<script setup lang="ts">
defineProps<{
  avatar?: {
    uses_photo: boolean;
    photo_data_uri?: string;
    color: string;
    initials: string;
  };
  big?: boolean;
}>();
</script>
