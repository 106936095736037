<template>
  <div class="flex flex-col gap-8 text-sm">
    <div class="grid max-w-max grid-cols-2 gap-x-4 gap-y-1">
      <template v-if="zorgvergoedingResult.data.aantal">
        <span>Behandelingen</span>
        {{ zorgvergoedingResult.data.aantal }}
      </template>
      <template v-if="zorgvergoedingResult.data.aantal2">
        <span>Ongecontracteerd</span>
        {{ zorgvergoedingResult.data.aantal2 }}
      </template>
      <template v-if="zorgvergoedingResult.data.bedrag">
        <span>Bedrag</span>
        {{ centToEuro(zorgvergoedingResult.data.bedrag) }}
      </template>
      <template v-if="zorgvergoedingResult.data.bedrag2">
        <span>Ongecontracteerd</span>
        {{ centToEuro(zorgvergoedingResult.data.bedrag2) }}
      </template>
      <template v-if="zorgvergoedingResult.data.mtaant">
        <span>Manuele therapie behandelingen</span>
        {{ zorgvergoedingResult.data.mtaant }}
      </template>
    </div>
    <div class="flex flex-col gap-2">
      <flux-input-box :label="'Vergoeding'">
        {{ zorgvergoedingResult.data.verg }}
      </flux-input-box>
      <flux-input-box :label="'Bijzonderheden'">
        {{ zorgvergoedingResult.data.bijz }}
      </flux-input-box>
      <flux-input-box :label="'Verwijzing'">
        {{ zorgvergoedingResult.data.verwijzing }}
      </flux-input-box>
      <flux-input-box :label="'Machtiging'">
        {{ zorgvergoedingResult.data.machtiging }}
      </flux-input-box>
      <flux-input-box :label="'Debit'">
        {{ zorgvergoedingResult.data.debit }}
      </flux-input-box>
      <flux-input-box
        v-if="zorgvergoedingResult.data.scrint"
        :label="'Screening en intake'"
      >
        {{ zorgvergoedingResult.data.scrint }}
      </flux-input-box>
      <flux-input-box :label="'DTF'">
        {{ zorgvergoedingResult.data.dtf }}
      </flux-input-box>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ZorgvergoedingResult } from "@/models/zorgvergoeding";
import { centToEuro } from "../../../libraries/utils";

defineProps<{
  zorgvergoedingResult: ZorgvergoedingResult;
}>();
</script>
